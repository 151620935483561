@import 'src/styles/mixins';
@import 'src/styles/variables';

.container {
  display: flex;
  flex-direction: column;
  gap: 10px;

  position: relative;
  padding: calc($grid_gap / 2);

  @include base-card;

  @media screen and (max-width: 400px) {
    padding: calc($grid_gap_mobile / 2);
  }

  &.clickable {
    cursor: pointer;
  }
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  flex: 1;
}

.header {
  display: flex;
  align-items: center;
  gap: 10px;
}

.cardWithPromo {
  padding-top: 30px;
  border: 1px solid var(--color-V100);
  border-radius: 12px 12px 16px 16px;

  @media screen and (max-width: $breakpoint-small-mobile) {
    border-radius: 16px 16px 16px 16px;
    padding-top: 24px;
  }
}
