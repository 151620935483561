@import 'src/styles/variables';

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-top: 10px;

  &:first-of-type {
    margin-top: 0;
  }
}

.label {
  color: var(--color-D30);

  [data-theme='guinness'] & {
    color: var(--color-D60);
  }

  white-space: nowrap;
  font-size: 13px;
  line-height: 16px;
}

.value {
  color: var(--color-D100);
  font-size: 15px;
  line-height: 18px;
  white-space: nowrap;

  text-align: end;
}
